import { Titled } from 'react-titled';
import type { FC } from 'react';
import TikTokForm from '../../features/tiktok/components/TikTokForm';

const TikTokPage: FC = () => (
  <>
    <Titled title={(title) => `WellTech User Acquisition | ${title}`} />
    <TikTokForm />
  </>
);

export default TikTokPage;
