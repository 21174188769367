import { useFormContext } from 'react-hook-form';
import {
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import type { SelectProps } from '../../../components/common/Select';
import Select from '../../../components/common/Select';
import { isEmpty } from 'lodash-es';
import { useAdTypes } from '../api/getAdTypes';
import type { AccountEntry } from '../api/getAccounts';
import { useAccounts } from '../api/getAccounts';
import Autocomplete from '../../../components/common/Autocomplete';
import { useCallToActions } from '../api/getCallToActions';
import { useConversionDomain } from '../api/getConversionDomain';
import type { AdSettingEntry, AdSettingPageEntry, AdSettingTextEntry } from '../api/getAdSettings';
import useAdSettings from '../api/getAdSettings';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import useRowsAdSets from '../hooks/useRowsAdSets';
import adSettingsMapper from '../utils/adSettings.mapper';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { RowResultEntry } from '../api/types';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import Tooltip from '@mui/material/Tooltip';

export const PacksWithCreatives = () => {
  const { onShowErrorAlert } = useEnqueueSnackbar();
  const { watch, setValue, trigger } = useFormContext();
  const packsWithCreatives = watch('packsWithCreatives') as string[][];
  const activePacksWithCreatives = watch('activePacksWithCreatives') as string;

  const handleDeletePack = useCallback((i: number) => {
    const clonePacksWithCreatives = [...packsWithCreatives];
    const cloneActivePacksWithCreatives = Number(activePacksWithCreatives);

    const splicePacksWithCreatives = packsWithCreatives?.filter((_, index) => index !== i);
    setValue('packsWithCreatives', splicePacksWithCreatives);

    if (activePacksWithCreatives === `${i}`) {
      setValue('selectedCreatives', []);
      setValue('activePacksWithCreatives', null);
    }
    onShowErrorAlert('Pack deleted', {
      delayAlert: 3500,
      onUndo: () => {
        setValue('selectedCreatives', clonePacksWithCreatives[cloneActivePacksWithCreatives]);
        setValue('packsWithCreatives', clonePacksWithCreatives);
        setValue('activePacksWithCreatives', cloneActivePacksWithCreatives);
      },
    });

    void trigger('packsWithCreatives');
  }, [onShowErrorAlert, activePacksWithCreatives, packsWithCreatives, setValue, trigger]);

  const handleSelectPack = useCallback((pack: string[], i: number) => {
    setValue('filterCreativeByName', '');
    setValue('selectedCreatives', pack);
    setValue('activePacksWithCreatives', i);
  }, [setValue]);

  if (isEmpty(packsWithCreatives)) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      {packsWithCreatives.map((pack, i) => (
        <Chip
          key={`${i}.${pack.join('')}`}
          label={`#${i + 1} Pack (${pack.length})`}
          onClick={() => {
            handleSelectPack(pack, i);
          }}
          onDelete={() => {
            handleDeletePack(i);
          }}
          color={String(activePacksWithCreatives) === String(i) ? 'primary' : 'default'}
        />))}
    </Box>
  );
};

export const AddPackButton = () => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const { watch, setValue, trigger } = useFormContext();
  const selectedCreatives = watch('selectedCreatives') as string[];
  const packsWithCreatives = watch('packsWithCreatives') as string[][];
  const marketingProcess = watch('marketingProcess') as string;
  const isAddPackAvailable = packsWithCreatives?.length <= 5 && selectedCreatives?.length && !['inbox_ua', 'inbox_mm'].includes(marketingProcess);
  const isPackAlreadyExists = selectedCreatives?.length && packsWithCreatives?.some(pack => pack.sort().join('') === selectedCreatives?.sort().join(''));

  const handleAddPack = useCallback(() => {
    if (isPackAlreadyExists) {
      onShowInfoAlert('Pack with the same set exists!', {
        delayAlert: 3500,
      });
      return;
    }

    const newPacksWithCreatives = [...packsWithCreatives, selectedCreatives];
    setValue('packsWithCreatives', newPacksWithCreatives);
    setValue('activePacksWithCreatives', newPacksWithCreatives.length - 1);

    onShowAlert('Pack created', {
      delayAlert: 3500,
    });

    void trigger('packsWithCreatives');
  }, [onShowInfoAlert, onShowAlert, setValue, isPackAlreadyExists, selectedCreatives, packsWithCreatives, trigger]);

  return (
    <Tooltip title={isAddPackAvailable ? '' : 'Not available for inbox campaigns'}>
      <span>
        <Button
          disabled={!isAddPackAvailable}
          variant="outlined"
          color="primary"
          onClick={handleAddPack}>Add pack</Button>
      </span>
    </Tooltip>

  );
};

type SelectAdTypes = Pick<SelectProps, 'handleChange'>
export const SelectAdTypes: FC<SelectAdTypes> = ({ handleChange }) => {
  const { watch } = useFormContext();
  const platform = watch('platform') as string;

  const { data, isLoading } = useAdTypes({
    params: { platform },
    config: {
      enabled: !!platform,
      select: (data) =>
        data.map((item) => ({
          value: item.code,
          label: item.name,
        })),
    },
  });
  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="adType"
      inputLabel="Ad type"
      disabled={isEmpty(data)}
      options={data ?? []}
      handleChange={handleChange}
    />
  );
};

export const SelectAdAccounts = () => {
  const { watch } = useFormContext();
  const app = watch('app') as string;
  const platform = watch('platform') as string;

  const dataMapper = (item: AccountEntry) => ({
    value: item.ad_account_id,
    label: item.name,
  });

  const { data, isError, error } = useAccounts({
    params: { platform, app },
    config: {
      select: (data) => data.map(dataMapper),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Ad Accounts'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while Ad Accounts'} />
    );
  }

  return (
    <Autocomplete
      id="adAccounts"
      name="adAccounts"
      InputProps={{
        label: 'Ad Accounts',
      }}
      options={data ?? []}
    />
  );
};

export const SelectCallToActions = () => {
  const { watch } = useFormContext();
  const platform = watch('platform') as string;

  const { data, isLoading, isError, error } = useCallToActions({
    params: { platform },
    config: {
      enabled: !!platform,
      select: (data) =>
        data.map((item) => ({
          value: item.code,
          label: item.name,
        })),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Call To Actions'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="callToActions"
      inputLabel="Call To Actions"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const SelectConversationDomain = () => {
  const { watch } = useFormContext();
  const app = watch('app') as string;

  const { data, isError, error } = useConversionDomain({
    params: { app },
    config: {
      select: (data) =>
        data.map((domain) => ({
          value: domain,
          label: domain,
        })),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Conversation domain'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return (
    <Select
      name="selectedConversionDomain"
      inputLabel="Conversation Domain"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const SelectAdSettings = () => {
  const { watch, setValue } = useFormContext();
  const { rows: allAdSets, isSuccess } = useRowsAdSets();
  const app = watch('app') as string;
  const platform = watch('platform') as string;
  const team = watch('team') as string;
  const selectedAdSets = watch('selectedAdSets') as string[];
  const adSets = allAdSets
    ?.filter(adSet => selectedAdSets?.includes(adSet.id))
    ?.map(({ account_id, name, id, campaign_id }) => ({ account_id, name, id, campaign_id })) ?? [];

  const { data: adSettings, isError, error, isSuccess: isSuccessAdSettings } = useAdSettings({
    params: { app, platform, marketing_team: team, ad_sets: adSets },
    config: {
      enabled: isSuccess,
      select: adSettingsMapper,
    },
  });

  useEffect(() => {
    if (isSuccessAdSettings) {
      const selectedSettings = (adSettings as AdSettingEntry[]).reduce((acc, {
        name,
        pages,
        texts,
      }) => {
        const defaultPage = pages?.find((page: AdSettingPageEntry) => page.is_default);
        const defaultText = texts?.find((text: AdSettingTextEntry) => text.is_default);

        return {
          ...acc,
          [name]: {
            page_id: defaultPage ? defaultPage.id : '',
            text_id: defaultText ? defaultText.id : '',
          },
        };
      }, {});

      if (!isEmpty(selectedSettings)) {
        setValue(`selectedAdSettings`, selectedSettings);
      }
    }
  }, [adSettings, setValue, isSuccessAdSettings]);

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Ad Settings'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return adSettings?.length ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
        Ad Settings
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {adSettings.map((setting: AdSettingEntry, i: number) => (
          <>
            <Box key={`${setting.name}.${i}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant={'body2'}>{setting.name}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 3 }}>
                <Box sx={{ flex: 1 }}>
                  <Select
                    disabled={!setting?.pages?.length}
                    name={`selectedAdSettings.${setting.name}.page_id`}
                    options={setting?.pages?.map(s => ({ value: s.id, label: s.name })) ?? []}
                    inputLabel={'Page'} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Select
                    disabled={!setting?.texts?.length}
                    sx={{ '.MuiSelect-select': { whiteSpace: 'wrap' } }}
                    name={`selectedAdSettings.${setting.name}.text_id`}
                    options={setting?.texts?.map(s => ({ value: s.id, label: s.name })) ?? []}
                    inputLabel={'Text'} />
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  ) : null;
};


export const JobResultTable = ({ rows }: { rows?: RowResultEntry[] }) => {
  if (!rows) return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
        Job Result
      </Typography>
      <Typography sx={t => ({ fontSize: 16, color: t.palette.error.main, mb: 2 })} gutterBottom>
        An error occurred while fetching data
      </Typography>
    </Box>
  );

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Ad Account</TableCell>
            <TableCell align="center">Adset</TableCell>
            <TableCell align="center">Creative</TableCell>
            <TableCell align="center">Result</TableCell>
            <TableCell align="center">Ad ID</TableCell>
            <TableCell align="center">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: RowResultEntry) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.account_id}
              </TableCell>
              <TableCell align="left">{row.ad_set}</TableCell>
              <TableCell align="left">{row.creative}</TableCell>
              <TableCell align="center">
                {row.success ? '✅' : '❌'}
              </TableCell>
              <TableCell align="center">{row.ad_id}</TableCell>
              <TableCell align="left">{row.output}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};