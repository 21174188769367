import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

export default function handleAPIPermissionError(error: unknown) {
  if (isAxiosError(error)) {
    enqueueSnackbar(`Unexpected error. We can't help with this issue now.`, {
      key: '500-error',
      variant: 'error',
      autoHideDuration: 5000,
    });
  }
}
