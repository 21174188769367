import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import queryKeys from '../api/queryKeys';
import type { CreativeEntry } from '../../../types/shared';

export interface CreativeParams {
  query?: string;
  app?: string;
  ad_type?: string;
  network?: string;
  display_metrics?: boolean;
}

export const getCreatives = async (
  { params }: { params: CreativeParams },
  signal?: AbortSignal,
): Promise<CreativeEntry[]> => {
  const res = await apiClient.get<CreativeEntry[]>(`/api/v1/creative_manager/get_creatives`, {
    params,
    signal,
    paramsSerializer: (prms) =>
      queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseCreativesOptions {
  params: CreativeParams;
  config?: SynchronousQueryConfig<typeof getCreatives, CreativeEntry[]>;
}

export const useCreatives = ({ params, config }: UseCreativesOptions) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreatives({ params }, signal),
    queryKey: queryKeys.creatives<CreativeParams>(params),
  });
};