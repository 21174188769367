import { Grid, Skeleton, Typography } from '@mui/material';
import { SelectAdAccounts } from './FacebookFormComponents';
import type { FC } from 'react';
import { Suspense } from 'react';

const AdAccountsForm: FC = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Choose Facebook Ad Accounts
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Suspense fallback={
            <Skeleton
              height={40}
              sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
            />
          }>
            <SelectAdAccounts />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};
export default AdAccountsForm;