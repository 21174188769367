import { Titled } from 'react-titled';
import type { FC } from 'react';
import FacebookForm from '../../features/facebook/components/FacebookForm';

const FacebookPage: FC = () => (
  <>
    <Titled title={(title) => `WellTech User Acquisition | ${title}`} />
    <FacebookForm />
  </>
);

export default FacebookPage;
