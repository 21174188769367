import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

export default function ScheduleFormDialog({
                                             open,
                                             handleClose,
                                             handleSchedule,
                                             onDateChange,
                                             onTimeChange,
                                             defaultDate,
                                             defaultTime,
                                           }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Scheduling posts</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select appropriate date and time.
          </DialogContentText>
          <Stack component="form" noValidate spacing={3} sx={{ mt: 5 }}>
            <TextField
              id="date"
              onChange={onDateChange}
              label="Publish date"
              type="date"
              defaultValue={defaultDate}
              sx={{ width: 310 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="time"
              onChange={onTimeChange}
              label="Publish time (UTC+3, Europe/Kiev)"
              type="time"
              defaultValue={defaultTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              sx={{ width: 310 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSchedule}>Schedule</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}