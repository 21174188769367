import { Titled } from 'react-titled';
import { Container, CssBaseline, List, ThemeProvider } from '@mui/material';
import type { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import SidebarNavItem from './SidebarNavItem';
import theme from '../../theme';
import { Facebook as FacebookIcon, PostAdd as PostAddIcon } from '@mui/icons-material';
import IconTikTok from 'assets/tiktok-svg.svg?react';

interface LayoutProps {
  children: ReactNode;
  title: string;
}

export const LayoutContainer: FC<LayoutProps> = ({ title, children }) => (
  <Titled title={title}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={() => ({
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100vh',
          minHeight: '100vh',
          zIndex: 1,
          gap: 0.75,
          p: 0.75,
          pt: 3,
          background: 'linear-gradient(113.18deg, #EDEFF7 100%, #EDEFF7 110.46%)',
          flexDirection: 'column',
        })}
      >
        {children}
      </Box>
    </ThemeProvider>
  </Titled>
);
const Layout: FC<LayoutProps> = ({ title, children }) => (
  <LayoutContainer title={title}>
    <Container component="main" maxWidth="lg"
               sx={{ mb: 4, display: 'flex', flexDirection: 'column', maxHeight: '100%' }}>
      <List sx={{
        '&': {
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'start',
          flexDirection: 'row',
          width: '100%',
          mb: -2,
        },
      }}>
        <SidebarNavItem
          to={`/`}
          title="Facebook Ads"
          icon={<FacebookIcon style={{ fontSize: 24, padding: 0 }} />}
          style={{ width: 'auto', paddingBottom: 4 }}
        />
        <SidebarNavItem
          to={`/tiktok`}
          title="TikTok Ads"
          icon={<IconTikTok width={20} height={24} />}
          style={{ width: 'auto', paddingBottom: 4 }}
        />
        <SidebarNavItem
          to={`/smm-poster`}
          title="SMM Poster"
          icon={<PostAddIcon style={{ fontSize: 24, padding: 0 }} />}
          style={{ width: 'auto', paddingBottom: 4 }}
        />
      </List>
      {children}
    </Container>
  </LayoutContainer>
);

export default Layout;
