import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import { apiClient } from '../lib/api/apiClient';
import queryKeys from './queryKeys';
import type { JobStatusResponse } from '../types/shared';
import { isAxiosError } from 'axios';

interface JobStatusParams {
  job_id: string | null;
}

export const getJobStatus = async (
  { params }: { params: JobStatusParams },
  signal?: AbortSignal,
): Promise<JobStatusResponse> => {
  const res = await apiClient.get<JobStatusResponse>(`/api/v1/job_status`, {
    params,
    signal,
    paramsSerializer: (prms) =>
      queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Job Status Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

interface UseJobStatusOptions {
  params: JobStatusParams;
  config?: SynchronousQueryConfig<typeof getJobStatus, JobStatusResponse>;
}

export const useJobStatus = ({ params, config }: UseJobStatusOptions) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    retryOnMount: false,
    retry: false,
    queryFn: ({ signal }) => getJobStatus({ params }, signal),
    queryKey: [...queryKeys.jobStatus, Object.values(params).join(',')] as QueryKey,
  });
};