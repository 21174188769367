import { apiClient } from '../../../lib/api/apiClient';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

const createTikTokAd = async (body) => {
  const res = await apiClient.post(
    `/api/v1/tiktok_ads_manager/create_ad/`,
    body
  );

  if (isAxiosError(res)) {
    const error = new Error('Axios error');
    error.cause = {
      ...res.response?.data,
      status: res.response?.status,
    };
    return Promise.reject(error);
  }
  return res.data;
};

const useCreateTikTokAd = ({ config } = {}) => {
  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: createTikTokAd,
  });
};

export { createTikTokAd, useCreateTikTokAd };
