import type { AxiosError } from 'axios';
import Axios, { isAxiosError } from 'axios';
import getCookie from '../../utils/getCookie';
import handleAPIPermissionError from '../../utils/handleAPIPermissionError';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const apiClient = Axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (isAxiosError(error) && error?.response && error?.response?.status === 403) {
      location.href = '/login';
      return;
    }
    if (isAxiosError(error) && error?.response && error?.response?.status >= 500) {
      handleAPIPermissionError(error);
    }
    return error;
  },
);
