import { useFormContext } from 'react-hook-form';
import { useCampaigns } from '../api/getCampaigns';
import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '../../../components/common/CircularProgress';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';
import type { MarketingProcess } from "../../../types/shared";

const COLUMNS = [
  { field: 'id', headerName: 'Campaign ID', width: 160 },
  { field: 'name', headerName: 'Campaign Name', width: 750 },
  { field: 'status', headerName: 'Status', width: 100 },
];

const CampaignsForm = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const facebookAccountId = watch('adAccounts') as string[];
  const selectedCampaigns = watch('selectedCampaigns') as string[];
  const isCampaignFieldError = errors.selectedCampaigns?.message as string | undefined;
  const marketingProcess = watch('marketingProcess') as MarketingProcess;

  const getCampaignNameFilter = (marketingProcess: MarketingProcess) => {
    switch (marketingProcess) {
      case 'testing':
        return 'CP-Test-Campaign-Template';
      case 'scaling':
        return 'Scale-Campaign-template';
      case 'inbox_ua':
      case 'inbox_mm':
        return 'inbox';
      default:
        return '';
    }
  }

  const { data: rows, isLoading, isPending, isError, error } = useCampaigns({
    params: { facebookAccountId, campaignNameFilter: getCampaignNameFilter(marketingProcess) },
  });

  const handleSelectionMode = useCallback(
    (rows: GridRowSelectionModel) => {
      setValue('selectedCampaigns', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedCampaigns');
  }, [trigger, selectedCampaigns]);

  if (isPending) {
    return <CircularProgress title={'Campaigns are loading...'} />; // Optional loading state
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Campaigns'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching campaigns'} />
    );
  }

  if (!rows?.length) {
    return <Typography>No campaigns found.</Typography>; // Handle no data case
  }

  return (
    <FormContainer title={'Choose Campaigns'} error={isCampaignFieldError}>
      {!isPending && rows?.length && <Box style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows ?? []}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedCampaigns}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
        />
      </Box>}
    </FormContainer>
  );
};

export default CampaignsForm;