import { useCallback, useState } from 'react';

interface Step {
  component: React.ComponentType;
}

interface UseStepperParams {
  initialSteps: Step[];
  defaultStep: number;
}

interface UseStepperReturn {
  initialSteps: Step[];
  currentStep: number;
  resetSteps: () => void;
  prevStep: () => void;
  nextStep: () => void;
  StepComponent: React.ComponentType;
}

const useStepper = ({ initialSteps, defaultStep }: UseStepperParams): UseStepperReturn => {
  const [currentStep, setCurrentStep] = useState<number>(defaultStep);

  const prevStep = useCallback(() => {
    setCurrentStep((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, [setCurrentStep]);

  const nextStep = useCallback(() => {
    setCurrentStep((prev) => {
      if (prev < initialSteps.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  }, [setCurrentStep, initialSteps]);

  const resetSteps = useCallback(() => {
    setCurrentStep(defaultStep);
  }, [setCurrentStep, defaultStep]);

  const StepComponent = initialSteps[currentStep].component;

  return {
    initialSteps,
    currentStep,
    resetSteps,
    prevStep,
    nextStep,
    StepComponent,
  };
};

export default useStepper;