import { apiClient } from '../../../lib/api/apiClient.ts';

export function getSmmCreatives() {
  return apiClient.get(`/api/v1/creative_manager/smm/creatives`);
}

export function getSmmPages(app) {
  return apiClient.get(`/api/v1/smm_poster/pages?app=${app}`);
}

export function getSmmPosts(app) {
  return apiClient.get(`/api/v1/smm_poster/templates?app=${app}`);
}

export function createFacebookPost(params) {
  return apiClient.post(`/api/v1/smm_poster/create_post`, params);
}

export function schedulePost(params) {
  return apiClient.post(`/api/v1/smm_poster/schedule_post`, params);
}
