import type { ComponentProps, FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material';

interface Option {
  value: string | number;
  label: string;
}

interface RadioGroupProps {
  name: string;
  label?: string;
  options: Option[];
  labelProps?: ComponentProps<typeof FormLabel>;
  controlProps?: ComponentProps<typeof FormControl>;
}

const RadioGroup: FC<RadioGroupProps> =
  ({
     name,
     label,
     options,
     labelProps,
     controlProps,
     ...restProps
   }) => {
    const { control, formState } = useFormContext<FieldValues>();
    const error = formState.errors[name];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FormControl {...controlProps}>
              {label && <FormLabel {...labelProps}>{label}</FormLabel>}
              <MuiRadioGroup
                {...field}
                value={String(field.value)}
                {...restProps}
              >
                {options.map(({ value, label }) => (
                  <FormControlLabel
                    key={String(value)}
                    value={value}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </MuiRadioGroup>
              {error?.message && (
                <Typography
                  variant="subtitle1"
                  fontSize={12}
                  sx={{ mt: '3px' }}
                  color="error"
                >
                  {error.message as string}
                </Typography>
              )}
            </FormControl>
          );
        }}
      />
    );
  };

export default RadioGroup;