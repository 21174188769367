import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/react-query';
import Layout, { LayoutContainer } from './components/layout/Layout';
import AuthProvider from './features/login/components/AuthProvider';
import LoginPage from './pages/login';
import TikTokPage from './pages/tiktok';
import FacebookPage from './pages/facebook';
import SmmAppPage from './pages/smm-poster';

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <AuthProvider>
            <Layout title="Facebook">
              <FacebookPage />
            </Layout>
          </AuthProvider>
        }
      />
      <Route
        path="smm-poster"
        element={
          <AuthProvider>
            <Layout title="SMM Poster">
              <SmmAppPage />
            </Layout>
          </AuthProvider>

        }
      />
      <Route
        path="tiktok"
        element={
          <AuthProvider>
            <Layout title="TikTok">
              <TikTokPage />
            </Layout>
          </AuthProvider>
        }
      />
      <Route
        path="login"
        element={
          <LayoutContainer title="Login">
            <LoginPage />
          </LayoutContainer>
        }
      />
    </Routes>
  </BrowserRouter>
);

const App = () => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        autoHideDuration={1000}
        maxSnack={1}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        preventDuplicate
      >
        <AppRoutes />
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

export default App;
