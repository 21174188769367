import * as React from 'react';
import { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { SmmAppContext } from '../context/app-context.js';
import { getSmmPages } from '../api/api.js';

export function PageForm() {
  const { state, setState } = useContext(SmmAppContext);

  useEffect(() => {

    setState(prevState => {
      return { ...prevState, ...{ smmPageList: [], selectedPageIds: [] } };
    });

    getSmmPages(state.selectedApp).then(response => {
      setState(prevState => {
        return { ...prevState, ...{ smmPageList: response.data } };
      });
    });
    // eslint-disable-next-line
  }, [state.selectedApp]);

  const columns = [
    // {field: 'id', headerName: 'ID', width: 100},
    { field: 'name', headerName: 'Name', width: 600 },
    { field: 'page_id', headerName: 'Page ID', width: 300 },
  ];

  const handleSelectionMode = (rows, details) => {
    const selectedRows = state.smmPageList.filter(item => rows.includes(item.id));
    setState(prevState => {
      return { ...prevState, ...{ selectedPages: selectedRows } };
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select Facebook Pages
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={state.smmPageList}
              columns={columns}
              checkboxSelection
              onSelectionModelChange={handleSelectionMode}
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
