import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import { apiClient } from '../../../lib/api/apiClient';
import { isAxiosError } from 'axios';
import queryString from 'query-string';
import queryKeys from './queryKeys';

export interface AdSetEntry {
  id: string;
  name: string;
  ads: number;
  status: string;
  account_id: string;
  campaign_id: string;
  disabled?: boolean;
}

interface GetAdSetsParams {
  selectedCampaigns: string[];
  platform: string;
}

export const getAdSets = async (
  { params }: { params: GetAdSetsParams },
  signal?: AbortSignal,
): Promise<AdSetEntry[]> => {
  const { selectedCampaigns, platform } = params;

  const promises = selectedCampaigns.map(id =>
    apiClient.get<AdSetEntry[]>(`/api/v1/facebook_manager/get_adsets/${id}`, {
      signal,
      params: { platform },
      paramsSerializer: (prms) =>
        queryString.stringify(prms, { arrayFormat: 'comma' }),
    }),
  );

  try {
    const responses = await Promise.all(promises);

    const isError = responses.some(r => isAxiosError(r));
    if (isError) {
      const errorResponses = responses.filter((response, index) => {
        const error = isAxiosError(response);
        if (error) {
          response.message = `An error occurred while fetching AdSet for campaign ${selectedCampaigns[index]}`;
        }
        return error;
      });

      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(errorResponses);
    }

    const successfulResponses = responses.filter(response => response?.status >= 200 && response?.status <= 400);
    return successfulResponses.flatMap(response => response.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

interface UseAdSetsOptions {
  params: GetAdSetsParams;
  config?: SynchronousQueryConfig<typeof getAdSets, AdSetEntry[]>;
}

export const useAdSets = ({ params, config }: UseAdSetsOptions) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    retryOnMount: false,
    retry: false,
    queryFn: ({ signal }) => getAdSets({ params }, signal),
    queryKey: [
      ...queryKeys.adSets,
      Object.values(params).join(','),
    ] as QueryKey,
  });
};