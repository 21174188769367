import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Switch as MuiSwitch, Tooltip, Typography } from '@mui/material';

type SizeSwitch = 'small' | 'medium';

interface SwitchProps {
  label?: string;
  name: string;
  sx?: object;
  size?: SizeSwitch;
  disabled?: boolean;
  disabledTitle?: string;
  handleChange?: () => void;
}

const Switch: FC<SwitchProps> =
  ({
     label,
     name,
     sx,
     size,
     disabled,
     disabledTitle,
     handleChange,
     ...restProps
   }) => {
    const { control } = useFormContext();

    const formElement = (
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
              <MuiSwitch
                sx={{ mr: 1, opacity: disabled ? 0.5 : 1 }}
                disabled={disabled}
                checked={value as boolean}
                onChange={(event, checked) => {
                  onChange(checked); // Corrected to only pass checked
                  if (handleChange) handleChange();
                }}
                size={size}
              />
            )}
          />
        }
        label={label ? (
          <Typography sx={{ fontSize: 'body2.fontSize' }}>{label}</Typography>
        ) : null}
        sx={{ mx: 0, mb: 1, py: 1 / 2, ...sx }}
        {...restProps}
      />
    );

    if (disabledTitle) {
      return (
        <Tooltip title={disabledTitle} placement={'top'}>
          {formElement}
        </Tooltip>
      );
    }

    return formElement;
  };

export default Switch;