import type { ComponentProps, FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select as MuiSelect, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface Option {
  value: string | number;
  label: string;
}

export interface SelectProps {
  name: string;
  options: Option[];
  label?: string;
  inputLabel?: string;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (event: SelectChangeEvent<unknown>) => void;
  PaperProps?: object;
  sx?: SxProps<Theme>;
  restProps?: ComponentProps<typeof MuiSelect>;
}

const Select: FC<SelectProps> =
  ({
     name,
     options,
     label,
     inputLabel,
     required,
     disabled,
     handleChange,
     sx,
     PaperProps,
     ...restProps
   }) => {
    const { control, formState, trigger } = useFormContext<FieldValues>();
    const error = formState.errors[name];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { value, onChange } = field;
          return (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              {label && !inputLabel && (
                <Typography variant="subtitle2" mb={1}>
                  {label}
                  {required && '*'}
                </Typography>
              )}
              <FormControl sx={{ flex: 1 }}>
                {!label && inputLabel && (
                  <InputLabel id={`${inputLabel}_select`}>
                    {inputLabel}
                  </InputLabel>
                )}
                <MuiSelect
                  labelId={inputLabel ? `${inputLabel}_select` : undefined}
                  {...field}
                  value={value ? String(value) : ''}
                  fullWidth
                  input={<OutlinedInput label={inputLabel ?? null} sx={{ whiteSpace: 'normal' }} />}
                  MenuProps={{
                    PaperProps: {
                      ...PaperProps,
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  error={!!error}
                  disabled={disabled}
                  sx={sx}
                  
                  {...restProps}
                  variant="outlined"
                  label={label ?? inputLabel}
                  onChange={async (event) => {
                    onChange(event);
                    await trigger(name);
                    handleChange && handleChange(event);
                  }}
                >
                  {options.map((o, i) => (
                    <MenuItem key={`${i}.${o.value}`} value={o.value} sx={{ wordBreak: 'break-all' }}>
                      {o.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
                {error?.message && (
                  <Typography
                    variant="subtitle1"
                    fontSize={12}
                    sx={{ mt: '3px' }}
                    color="error"
                  >
                    {error.message as string}
                  </Typography>
                )}
              </FormControl>
            </Box>
          );
        }}
      />
    );
  };

export default Select;