import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import type { SynchronousQueryConfig } from '../../../lib/react-query';
import queryKeys from './queryKeys';
import { isAxiosError } from 'axios';

interface GetCallToActionsParams {
  platform?: string;
}

export interface CallToActionEntry {
  code: string;
  name: string;
}

const getCallToActions = async (params: GetCallToActionsParams, signal?: AbortSignal): Promise<CallToActionEntry[]> => {
  const res = await apiClient.get<CallToActionEntry[]>(`/api/v1/facebook_manager/get_call_to_actions`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Call To Actions Failed');
    error.cause = res?.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseCallToActions<TData> {
  config?: SynchronousQueryConfig<typeof getCallToActions, TData>;
  params: GetCallToActionsParams;
}

export const useCallToActions = <TData = CallToActionEntry[]>({ config, params }: UseCallToActions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCallToActions(params, signal),
    queryKey: [
      ...queryKeys.callToActions,
      Object.values(params).join(','),
    ],
  });
};