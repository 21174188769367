import type { SelectChangeEvent} from '@mui/material';
import { Grid, Typography } from '@mui/material';
import SelectApplications from '../../../components/common/SelectApplications';
import SelectMarketingProcess from '../../../components/common/SelectMarketingProcess';
import SelectPlatform from '../../../components/common/SelectPlatform';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { getMarketingTeamByProcess } from "../../../utils/getMarketingTeamByProcess";
import type { MarketingProcess } from "../../../types/shared";

const AppPlatformForm: FC = () => {
  const { setValue } = useFormContext();

  const handleSelectMarketingProcessChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setValue('team', getMarketingTeamByProcess(event.target.value as MarketingProcess));
    },
    [setValue],
  );

  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Choose App & Platform
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectApplications />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectPlatform />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectMarketingProcess handleChange={handleSelectMarketingProcessChange} />
        </Grid>
      </Grid>
    </>
  );
};
export default AppPlatformForm;