import { useFormContext } from 'react-hook-form';
import { useAdSets } from '../api/getAdSets';

const useRowsAdSets = () => {
  const { watch } = useFormContext();
  const selectedCampaigns = watch('selectedCampaigns') as string[];
  const platform = watch('platform') as string;

  const { data: rows, isSuccess, isLoading, isPending, isError, error } = useAdSets({
    params: { selectedCampaigns, platform },
  });

  return {
    rows,
    isSuccess,
    isLoading,
    isPending,
    isError,
    error,
  };
};

export default useRowsAdSets;