import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import { isAxiosError } from 'axios';

export const getAccounts = async ({ params }, signal) => {
  const res = await apiClient.get(`/api/v1/tiktok_ads_manager/accounts`, {
    params,
    signal,
    paramsSerializer: (prms) =>
      queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Ad Accounts Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

export const useAccounts = ({ params, config }) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAccounts({ params }, signal),
    queryKey: [
      'api',
      'v1',
      'tiktok_ads_manager',
      'accounts',
      Object.values(params).join(','),
    ],
  });
};
