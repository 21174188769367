import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppForm from './AppForm';
import { SmmAppContext } from '../context/app-context';
import { PageForm } from './Pages';
import PostForm from './Post';

const steps = ['App & Campaign', 'Pages', 'Post Content'];

const initialState = {
  activeStep: 0,
  selectedApp: 'YG',
  selectedCampaignName: '',
  selectedCreative: null,
  selectedPages: [],
  postTemplateContent: '',
  selectedPostId: '',
  smmCampaignNameList: [],
  smmCreativesList: [],
  smmPageList: [],
  smmPostTemplateList: [],
};

// eslint-disable-next-line react/prop-types
function SmmAppContextProvider({ children }) {
  const [state, setState] = React.useState(initialState);
  return (
    <SmmAppContext.Provider value={{ state, setState }}>
      {children}
    </SmmAppContext.Provider>
  );
}

export default function SmmApp() {
  const [activeStep, setActiveStep] = React.useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AppForm />;
      case 1:
        return <PageForm />;
      case 2:
        return <PostForm />;
      default:
        throw Object('Unknown step');
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Paper
      variant="outlined"
      sx={{ p: { xs: 2, md: 3 } }}
    >
      <Typography component="h1" variant="h4">
        Facebook Poster
      </Typography>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Congratulations! 🎉
            </Typography>
            <Typography variant="subtitle1">
              The request has been sent, please wait until all ads are ready 🙏
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SmmAppContextProvider>
              {getStepContent(activeStep)}
            </SmmAppContextProvider>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}

              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Next
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    </Paper>
  );
}
