import type { FC } from 'react';
import Select, { type SelectProps } from './Select';
import { MARKETING_PROCESS } from '../../constants';


interface Process {
  name: string;
  code: string;
}

type SelectMarketingProcessProps = Partial<Pick<SelectProps, 'handleChange' | 'name' | 'inputLabel'>>;

const SelectMarketingProcess: FC<SelectMarketingProcessProps> =
  ({
     handleChange,
     name,
     inputLabel,
   }) => {
    const listMapper = ({ name, code }: Process) => ({
      label: name,
      value: code,
    });

    return (
      <Select
        name={name ?? 'marketingProcess'}
        inputLabel={inputLabel ?? 'Marketing Process'}
        options={MARKETING_PROCESS.map(listMapper)}
        handleChange={handleChange}
      />
    );
  };

export default SelectMarketingProcess;