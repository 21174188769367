import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const SuspenseErrorFallback = ({ title, message }: { title: string; message: string }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
        {title}
      </Typography>
      <Typography sx={t => ({ fontSize: 16, color: t.palette.error.main, mb: 2 })} gutterBottom>
        {message}
      </Typography>
    </Box>
  );
};

export default SuspenseErrorFallback;