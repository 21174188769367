import { Titled } from 'react-titled';
import type { FC } from 'react';
import SmmApp from '../../features/smm-poster/components/SmmPoster';

const SmmAppPage: FC = () => (
  <>
    <Titled title={(title) => `WellTech User Acquisition | ${title}`} />
    <SmmApp />
  </>
);

export default SmmAppPage;