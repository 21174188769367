import type { FC, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router';
import Box from '@mui/material/Box';
import useCheckAuth from '../hooks/useCheckAuth';

interface AuthProvider {
  children: ReactNode;
}

const AuthProvider: FC<AuthProvider> = ({ children }) => {
  const { isAuthenticated, isLoading, isSuccess } = useCheckAuth();

  if (isLoading) {
    return <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}><CircularProgress /></Box>;
  }

  if (isSuccess && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;

};
export default AuthProvider;
