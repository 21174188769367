import type { FC } from 'react';
import { APP_PLATFORMS } from '../../constants';
import Select, { type SelectProps } from './Select';

interface Platform {
  name: string;
  code: string | number; // Adjust the type based on your actual data
}

type SelectPlatformProps = Partial<Pick<SelectProps, 'handleChange' | 'name' | 'inputLabel'>>;

const SelectPlatform: FC<SelectPlatformProps> =
  ({
     handleChange,
     name,
     inputLabel,
   }) => {
    const listMapper = ({ name, code }: Platform) => ({
      label: name,
      value: code,
    });

    return (
      <Select
        name={name ?? 'platform'}
        inputLabel={inputLabel ?? 'Platform'}
        options={APP_PLATFORMS.map(listMapper)}
        handleChange={handleChange}
      />
    );
  };

export default SelectPlatform;