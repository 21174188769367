import { Box, Skeleton, Typography } from '@mui/material';
import { SelectAdSettings, SelectCallToActions, SelectConversationDomain } from './FacebookFormComponents';
import type { FC } from 'react';
import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../components/common/TextField';
import Switch from '../../../components/common/Switch';
import { WEB_PLATFORM } from '../../../constants';
import { ErrorBoundary } from 'react-error-boundary';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';

const AdForm: FC = () => {
  const { watch } = useFormContext();
  const platform = watch('platform') as string;
  const isWebPlatform = platform === WEB_PLATFORM;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Create Ad
      </Typography>
      <Box sx={{ display: 'flex', gap: 4, flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', width: '50%' }}>
          <SelectCallToActions />
          <TextField name={'adLabelSuffix'} inputLabel={'Ad label suffix'}
                     helperText="Optional text at the end of ad label" />
          {isWebPlatform &&
            <ErrorBoundary
              fallback={
                <SuspenseErrorFallback
                  title={'Conversation Domain'}
                  message={'Error occur'}
                />
              }>
              <Suspense
                fallback={
                  <Skeleton
                    height={40}
                    sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
                  />
                }>
                <SelectConversationDomain />
              </Suspense>
            </ErrorBoundary>
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', width: '100%', gap: 2 }}>
            <Switch name={'activeCampaigns'} label={'Active Campaigns'} />
            {isWebPlatform && <Switch name={'hotjar'} label={'Hotjar'} />}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', width: '100%' }}>
          <ErrorBoundary
            fallback={
              <SuspenseErrorFallback
                title={'Ad Settings'}
                message={'Error occur'}
              />
            }>
            <Suspense
              fallback={
                <Skeleton
                  height={40}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
                />
              }>
              <SelectAdSettings />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
    </>
  );
};

export default AdForm;