import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SmmAppContext } from '../context/app-context.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { createFacebookPost, getSmmPosts, schedulePost } from '../api/api.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import ScheduleFormDialog from './ScheduleFormDialog.jsx';
import moment from 'moment';
import CreatedPostTable from './CreatedPostTable.jsx';
import ScheduledPostTable from './ScheduledPostTable.jsx';

function ResultTable(props) {
  const isScheduled = props.scheduled;
  const rows = props.rows;

  return isScheduled === true ? (
    <ScheduledPostTable rows={rows} />
  ) : (
    <CreatedPostTable rows={rows} />
  );
}

export default function PostForm() {
  const initialLocalState = {
    loading: false,
    response: [],
    isComplete: false,
    isScheduled: false,
    scheduleDialogOpen: false,
    scheduledDate: moment().format('YYYY-MM-DD'),
    scheduledTime: '22:00',
  };

  const { state, setState } = useContext(SmmAppContext);
  const [localState, setLocalState] = useState(initialLocalState);

  useEffect(() => {
    getSmmPosts(state.selectedApp).then(response => {
      setState(prevState => {
        return { ...prevState, ...{ smmPostTemplateList: response.data } };
      });
    });  // eslint-disable-next-line
  }, [state.selectedApp]);

  const smmPostTemplateList = state.smmPostTemplateList.map(item => {
    return (
      <MenuItem key={item.id} value={item.id}>{item.short_text}</MenuItem>
    );
  });

  const handleSelectPostTemplateChange = (event) => {
    setState(prevState => {
      const postTemplateId = event.target.value;
      const postContent = state.smmPostTemplateList.find((el, index, array) => {
        return el.id === postTemplateId;
      });
      return { ...prevState, ...{ postTemplateContent: postContent.text, selectedPostId: postTemplateId } };
    });
  };

  const handlePostCreate = (event) => {
    const params = {
      pages: state.selectedPages,
      creative_id: state.selectedCreative.id,
      post_template: state.postTemplateContent,
      campaign_name: state.selectedCampaignName,
    };

    setLocalState(prevState => {
      return { ...prevState, ...{ loading: true } };
    });

    createFacebookPost(params).then(response => {
      setLocalState(prevState => {
        return { ...prevState, ...{ response: response.data } };
      });
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setLocalState(prevState => {
        return { ...prevState, ...{ loading: false, isComplete: true, isScheduled: false } };
      });
    });
  };

  const openScheduleDialog = (event) => {
    setLocalState(prevState => {
      return { ...prevState, ...{ scheduleDialogOpen: true } };
    });
  };

  const handleScheduleBtn = (event) => {
    const params = {
      pages: state.selectedPages,
      creative_id: state.selectedCreative.id,
      post_template: state.postTemplateContent,
      campaign_name: state.selectedCampaignName,
      schedule_time: localState.scheduledTime,
      schedule_date: localState.scheduledDate,
    };
    setLocalState(prevState => {
      return { ...prevState, ...{ loading: true } };
    });
    schedulePost(params).then(response => {
      setLocalState(prevState => {
        return { ...prevState, ...{ response: response.data } };
      });
      console.log(response);
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setLocalState(prevState => {
        return { ...prevState, ...{ loading: false, isComplete: true, isScheduled: true } };
      });
    });
  };

  return localState.isComplete === false ? (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select Your Post
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControl sx={{ m: 1, width: 800 }}>
            <InputLabel variant="outlined" id="post-template-label-id">Post Template</InputLabel>
            <Select
              labelId="post-template-label-id"
              id="post-template-id"
              value={state.selectedPostId}
              label="Post Template"
              onChange={handleSelectPostTemplateChange}
              fullWidth
            >
              {smmPostTemplateList}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} sm={8}>
          <FormControl sx={{ m: 1, width: 800 }}>
            <TextField
              id="post-content-id"
              label="Post Content"
              multiline
              rows={10}
              value={state.postTemplateContent}
              onChange={(event) => {
                setState(prevState => {
                  return { ...prevState, ...{ postTemplateContent: event.target.value } };
                });
              }}
            />
          </FormControl>

          {!localState.loading ? (
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                onClick={handlePostCreate}
                sx={{ ml: 1 }}
              >Create Post</Button>

              <Button
                variant="outlined"
                onClick={openScheduleDialog}
              >Schedule Post</Button>

              <ScheduleFormDialog
                open={localState.scheduleDialogOpen}
                handleClose={() => setLocalState(prevState => {
                  return { ...prevState, ...{ scheduleDialogOpen: false } };
                })}
                handleSchedule={handleScheduleBtn}
                onDateChange={(e) => setLocalState(prevState => {
                  return { ...prevState, ...{ scheduledDate: e.target.value } };
                })}
                onTimeChange={(e) => setLocalState(prevState => {
                  return { ...prevState, ...{ scheduledTime: e.target.value } };
                })}
                defaultTime={localState.scheduledTime}
                defaultDate={localState.scheduledDate}
              />
            </Stack>

          ) : (
            <div>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={localState.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )
          }
        </Grid>
      </Grid>
    </React.Fragment>
  ) : (
    <ResultTable rows={localState.response} scheduled={localState.isScheduled} />
  );
}
