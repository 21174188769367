import * as React from 'react';
import { useContext, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { APP_LIST } from '../../../constants';
import { getSmmCreatives } from '../api/api';
import { SmmAppContext } from '../context/app-context';
import FormContainer from '../../../components/layout/FormContainer';
import { Box } from '@mui/material';

export default function AppForm() {
  const { state, setState } = useContext(SmmAppContext);
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    getSmmCreatives().then(response => {
      setState(prevState => {
        return {
          ...prevState, ...{
            smmCreativesList: response.data,
            smmSelectedCreative: response.data[0],
          },
        };
      });
    });  // eslint-disable-next-line
  }, []);

  const handleSelectAppChange = (event) => {
    setState(prevState => {
      return { ...prevState, ...{ selectedApp: event.target.value } };
    });
  };

  const appList = APP_LIST.map(item => {
    return (
      <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
    );
  });

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
  });

  return (
    <FormContainer title={'Choose App & Campaign'}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'start', width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel variant="outlined" id="application-label-id">Application</InputLabel>
            <Select
              labelId="application-label-id"
              id="app-id"
              value={state.selectedApp}
              label="Application"
              onChange={handleSelectAppChange}
              fullWidth
              variant="outlined">
              {appList}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <FormControl>
            <TextField
              id="campaign-name"
              required
              value={state.selectedCampaignName}
              label="Campaign Name"
              onChange={(event) => {
                setState(prevState => {
                  return { ...prevState, ...{ selectedCampaignName: event.target.value } };
                });
              }}
              fullWidth
            >
            </TextField>
          </FormControl>
          <FormControl>
            <Autocomplete
              value={state.selectedCreative}
              onChange={(event, newValue) => {
                setState(prevState => {
                  return { ...prevState, ...{ selectedCreative: newValue } };
                });
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="creative-select-id"
              options={state.smmCreativesList}
              getOptionLabel={(option) => option.name}
              fullWidth
              filterOptions={filterOptions}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Creative" />}
            />
          </FormControl>
        </Box>
      </Box>
    </FormContainer>
  );
}
