import type * as React from 'react';
import type { FC, ReactNode } from 'react';
import { Box, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';

export interface StepType {
  title: string;
  component: React.ComponentType;
}

export interface StepperPaperProps {
  title?: string;
  steps: StepType[];
  activeStep: number;
  children?: ReactNode;
}

const StepperPaper: FC<StepperPaperProps> = ({ title, steps, activeStep, children }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        pt: { xs: 2, md: 3 },
        px: { xs: 2, md: 3 },
        border: 'none',
        borderRadius: '8px',
        maxHeight: '85vh',
        overflow: 'auto',
        boxShadow: '12px 13px 26px 5px rgb(163 145 203 / 6%)',
      }}
    >
      {title && (
        <Box sx={{ display: 'flex' }}>
          <Typography component="h1" variant="h6" align="left">
            {title}
          </Typography>
        </Box>
      )}
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {steps.map((step) => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
    </Paper>
  );
};

export default StepperPaper;