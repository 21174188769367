import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import type { GridRowsProp } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '../../../components/common/CircularProgress';
import useRowsAdSets from '../hooks/useRowsAdSets';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';

interface AdSet {
  id: string;
  name: string;
  ads: number;
  status: string;
  disabled?: boolean;
}

const COLUMNS = [
  { field: 'name', headerName: 'Adset Name', width: 850 },
  { field: 'ads', headerName: '№ Ads' },
  { field: 'status', headerName: 'Status' },
];

const AdSetsForm = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const selectedAdSets = watch('selectedAdSets') as string[];
  const isAdSetsFieldError = errors.selectedAdSets?.message;

  const { rows, isLoading, isPending, isError, error } = useRowsAdSets();

  const handleSelectionMode = useCallback(
    (rows: GridRowSelectionModel) => {
      setValue('selectedAdSets', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedAdSets');
  }, [trigger, selectedAdSets]);

  if (isPending) {
    return <CircularProgress title={'Ad Sets are loading...'} />;
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Ad Sets'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching ad sets'} />
    );
  }

  if (!rows?.length) {
    return <Typography>No ad sets found.</Typography>;
  }

  return (
    <FormContainer title={'Choose Ad Sets'} error={isAdSetsFieldError as string}>
      {!isPending && rows?.length && <Box style={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={rows as GridRowsProp<AdSet>}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedAdSets}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
          getRowId={(row) => row.id}
          isRowSelectable={(params) => !params.row.disabled}
          getRowClassName={(params) => params.row.disabled ? 'row-disabled' : ''}
          sx={t => ({ '.row-disabled': { color: t.palette.action.disabled } })}
        />
      </Box>}
    </FormContainer>

  );
};

export default AdSetsForm;