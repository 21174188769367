import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, OutlinedInput as MuiOutlinedInput, Typography } from '@mui/material';

interface OutlinedInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OutlinedInput: React.FC<OutlinedInputProps> =
  ({
     name,
     label,
     disabled,
     handleChange,
     ...restProps
   }) => {
    const { control, formState, trigger }: UseFormReturn = useFormContext();
    const error = formState.errors[name];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { value, onChange } = field;
          return (
            <FormControl
              sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
            >
              {label && (
                <InputLabel
                  htmlFor="display-name"
                  sx={{
                    background: 'white',
                    px: 0.5,
                    left: -5,
                    display: 'inline-block',
                  }}
                >
                  {label}
                </InputLabel>
              )}
              <MuiOutlinedInput
                {...field}
                value={(value as string) ?? ''}
                fullWidth
                error={!!error}
                disabled={disabled}
                {...restProps}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event);
                  await trigger(name);
                  handleChange && handleChange?.(event);
                }}
              />
              {error?.message && (
                <Typography
                  variant="subtitle1"
                  fontSize={12}
                  sx={{ mt: '3px' }}
                  color="error"
                >
                  {error?.message as string}
                </Typography>
              )}
            </FormControl>
          );
        }}
      />
    );
  };

export default OutlinedInput;