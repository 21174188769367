import { useSuspenseQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import type { QueryConfig } from '../../../lib/react-query';
import queryKeys from './queryKeys';
import { isAxiosError } from 'axios';

export interface GetAccountsParams {
  platform?: string;
  app?: string;
}

export interface AccountEntry {
  ad_account_id: string;
  name: string;
}

const getAccounts = async (params: GetAccountsParams, signal?: AbortSignal): Promise<AccountEntry[]> => {
  const res = await apiClient.get<AccountEntry[]>(`/api/v1/facebook_manager/get_ad_accounts/`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Ad Accounts Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseAccounts<TData> {
  config?: QueryConfig<typeof getAccounts, TData>;
  params: GetAccountsParams;
}

export const useAccounts = <TData = AccountEntry[]>({ config, params }: UseAccounts<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAccounts(params, signal),
    queryKey: [
      ...queryKeys.adAccounts,
      Object.values(params).join(','),
    ],
  });
};