import type { FC } from 'react';
import type { SelectProps } from './Select';
import Select from './Select';
import { APP_LIST } from '../../constants';

interface Application {
  name: string;
  code: string | number;
}

type SelectApplicationsProps = Partial<Pick<SelectProps, 'handleChange' | 'name' | 'inputLabel'>>;

const SelectApplications: FC<SelectApplicationsProps> =
  ({
     handleChange,
     name,
     inputLabel,
   }) => {
    const listMapper = ({ name, code }: Application) => ({
      label: name,
      value: code,
    });

    return (
      <Select
        name={name ?? 'app'}
        inputLabel={inputLabel ?? 'Application'}
        options={APP_LIST.map(listMapper)}
        handleChange={handleChange}
      />
    );
  };

export default SelectApplications;