import { createRoot } from 'react-dom/client';
// import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const container = document.getElementById('root');
if (!container) {
  throw new Error('React container not found');
}

const root = createRoot(container);
root.render(<App />);
