import { Button, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface SidebarNavItemProps {
  to?: string;
  icon?: ReactNode;
  title: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({ to, icon, title, style, disabled, onClick, ...props }) => {
  const path = useResolvedPath(to ?? '');
  const match = useMatch({ path: path.pathname, caseSensitive: true });
  const isActive = match != null;
  const component = to ? Link : 'button';

  const onClickProp = useMemo(() => {
    if (to) return undefined;
    if (onClick) return onClick;

    return undefined;
  }, [to, onClick]);

  return (
    <ListItem style={{ ...style }} {...props} sx={{ p: 0 }}>
      <Button
        variant="text"
        component={component}
        to={to}
        replace
        disableElevation
        disableRipple={isActive}
        disableTouchRipple
        disableFocusRipple
        sx={(t) => ({
          display: 'flex',
          gap: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1,
          px: 2,
          fontSize: t.typography.caption.fontSize,
          textDecoration: 'none',
          textTransform: 'capitalize',
          borderRadius: '8px 8px 0 0',
          color: isActive ? t.palette.primary.main : t.palette.text.secondary,
          backgroundColor: isActive ? 'white' : 'transparent',
          position: 'relative',
          transition: 'none',

          '& svg': {
            fill: isActive ? t.palette.primary.main : t.palette.text.secondary,
          },

          // Pseudo-element for active tab corner
          '&::after': {
            content: isActive ? '""' : 'none',
            position: 'absolute',
            right: '-19px', // Adjust as needed
            top: '2px',
            transform: 'translateY(0)',
            width: '21px', // Width of the corner
            height: 'calc(100% - 2px)', // Height of the corner
            backgroundColor: 'white',
            clipPath: 'polygon(0 0, 100% 100%, 0 100%)', // Create a triangle shape
          },

          '&:hover': {
            color: t.palette.primary.main,
            backgroundColor: isActive ? 'white' : 'transparent',
          },
          '&:hover .MuiSvgIcon-root': {
            color: t.palette.primary.main,
          },
          '&[disabled]': {
            color: t.palette.text.disabled,
          },
        })}
        onClick={onClickProp}
        disabled={disabled}
      >
        <ListItemIcon
          sx={(t) => ({
            p: 0,
            minWidth: 24,
            justifyContent: 'center',
            color: isActive ? t.palette.primary.main : t.palette.text.secondary,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={(t) => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500, letterSpacing: '0.4px' })}
            >
              {title}
            </Typography>
          }
        />
      </Button>
    </ListItem>
  );
};

export default SidebarNavItem;
