import { useSuspenseQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import type { QueryConfig } from '../../../lib/react-query';
import queryKeys from './queryKeys';
import { isAxiosError } from 'axios';

interface GetConversionDomainParams {
  app?: string;
}

const getConversionDomain = async (params: GetConversionDomainParams, signal?: AbortSignal): Promise<string[]> => {
  const res = await apiClient.get<string[]>(`/api/v1/facebook_manager/get_domains`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Conversion Domain Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

interface UseConversionDomain<TData> {
  config?: QueryConfig<typeof getConversionDomain, TData>;
  params: GetConversionDomainParams;
}

export const useConversionDomain = <TData = string[]>
({
   config,
   params,
 }: UseConversionDomain<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getConversionDomain(params, signal),
    queryKey: [
      ...queryKeys.domains,
      Object.values(params).join(','),
    ],
  });
};