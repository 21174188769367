import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import type { SynchronousQueryConfig } from '../../../lib/react-query';
import queryKeys from './queryKeys';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

interface GetAdTypesParams {
  platform: string;
}

interface GetAdTypeEntry {
  code: string;
  name: string;
}

const getAdTypes = async (params: GetAdTypesParams, signal?: AbortSignal): Promise<GetAdTypeEntry[]> => {
  const res = await apiClient.get<GetAdTypeEntry[]>(`/api/v1/facebook_manager/get_ad_types`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res)) {
    const error = new Error('Get Ad Types Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

interface UseAdTypes<TData> {
  config?: SynchronousQueryConfig<typeof getAdTypes, TData>;
  params: GetAdTypesParams;
}

export const useAdTypes = <TData = GetAdTypeEntry[]>({ config, params }: UseAdTypes<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAdTypes(params, signal),
    queryKey: [
      ...queryKeys.adTypes,
      Object.values(params).join(','),
    ],
  });
};