import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from '../../../lib/api/apiClient';
import { isAxiosError } from 'axios';

export const getCampaigns = async ({ params }, signal) => {
  const res = await apiClient.get(
    `/api/v1/tiktok_ads_manager/get_campaigns/${params.facebookAccountId}`,
    { signal },
  );

  if (isAxiosError(res)) {
    const error = new Error('Get Campaigns Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

export const useCampaigns = ({ params, config }) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCampaigns({ params }, signal),
    queryKey: [
      'api',
      'v1',
      'tiktok_ads_manager',
      'get_campaigns',
      params.facebookAccountId,
    ],
  });
};
