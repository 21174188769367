import type { FC } from 'react';
import { Box, CircularProgress as MuiCircularProgress, Typography } from '@mui/material';

interface CircularProgressProps {
  title?: string;
}

const CircularProgress: FC<CircularProgressProps> = ({ title }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 200,
      }}
    >
      <MuiCircularProgress />
      {title && <Typography sx={t => ({ fontSize: t.typography.body1.fontSize })}>{title}</Typography>}
    </Box>
  );
};

export default CircularProgress;