import { Button, Typography } from '@mui/material';
import useCheckAuth from '../hooks/useCheckAuth';
import { Navigate } from 'react-router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const { isAuthenticated, isLoading } = useCheckAuth();

  if (isLoading) {
    return <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}><CircularProgress /></Box>;
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={() => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
      })}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'center',
        backgroundColor: 'white',
        minWidth: '320px',
        minHeight: '160px',
        borderRadius: 1,
      }}>
        <Typography variant="h4">
          You are not authorized
        </Typography>
        <Button variant="contained" component={'a'} href={process.env.REACT_APP_LOGIN_REDIRECT_URL}>Authorize</Button>
      </Box>
    </Box>
  );
};

export default Login;