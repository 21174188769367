import type { ComponentProps, FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Chip, FormControl, FormLabel, Typography } from '@mui/material';

interface Option {
  value: string | number;
  label: string;
}

interface ChipGroupProps {
  name: string;
  label?: string;
  options: Option[];
  labelProps?: ComponentProps<typeof FormLabel>;
  controlProps?: ComponentProps<typeof FormControl>;
}

const ChipGroup: FC<ChipGroupProps> =
  ({
     name,
     label,
     options,
     labelProps,
     controlProps,
   }) => {
    const { control, formState, trigger } = useFormContext<FieldValues>();
    const error = formState.errors[name];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { value, onChange } = field;
          const fieldValue = value as string[];
          return (
            <FormControl {...controlProps}>
              {label && (
                <FormLabel
                  sx={(t) => ({
                    fontSize: t.typography.subtitle2.fontSize,
                    color: '#000',
                    fontWeight: 500,
                  })}
                  {...labelProps}
                >
                  {label}
                </FormLabel>
              )}
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {options.map(({ value, label }, i) => (
                  <Chip
                    key={`${i}.${value}`}
                    label={label}
                    defaultValue={value}
                    clickable
                    color={fieldValue?.includes(value as string) ? 'primary' : 'default'}
                    onClick={async () => {
                      if (fieldValue?.includes(value as string)) {
                        onChange(fieldValue.filter((v) => v !== value));
                      } else {
                        onChange([...(fieldValue ?? []), value]);
                      }
                      await trigger(name);
                    }}
                  />
                ))}
              </Box>
              {error?.message && (
                <Typography
                  variant="subtitle1"
                  fontSize={12}
                  sx={{ mt: '3px' }}
                  color="error"
                >
                  {error.message as string}
                </Typography>
              )}
            </FormControl>
          );
        }}
      />
    );
  };

export default ChipGroup;