import { Suspense, useCallback, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useFormContext, useWatch } from 'react-hook-form';
import Select from '../../../components/common/Select';
import {
  AGE_RANGE_ITEMS,
  BID_STRATEGIES_LIST,
  DEFAULT_TARGET_CPA,
  DEFAULT_TARGET_ROAS,
  GENDER_ITEMS,
  SPENDING_POWER_ITEMS,
  TEAM_ITEMS,
  TIKTOK_ADS_AD_LIST,
} from '../../../constants';
import { useAccounts } from '../api/getAccounts';
import { DataGrid } from '@mui/x-data-grid';
import { useCampaigns } from '../api/getCampaigns';
import { useTexts } from '../api/getTexts';
import { useFlows } from '../api/getFlows';
import TextField from '../../../components/common/TextField';
import RadioGroup from '../../../components/common/RadioGroup';
import OutlinedInput from '../../../components/common/OutlinedInput';
import ChipGroup from '../../../components/common/ChipGroup';
import SelectApplications from '../../../components/common/SelectApplications';
import useRowsCreatives from '../../creatives/hooks/useRowsCreatives';
import FormContainer from '../../../components/layout/FormContainer';

export const AdAccountSelect = () => {
  const { watch } = useFormContext();
  const app = watch('app');

  const { data, isLoading } = useAccounts({
    params: { app, platform: 'WEB' },
    config: {
      enabled: !!app,
      select: (data) =>
        data.map((item) => ({
          value: item.account_id,
          label: item.name,
        })),
    },
  });
  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="adAccounts"
      inputLabel="Ad Account"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const AdSettings = () => {
  const { setValue } = useFormContext();

  const listMapper = ({ name, code }) => ({
    label: name,
    value: code,
  });

  return (
    <FormContainer title={'Choose App and Ad Type'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'start', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <SelectApplications name={'app'} onChange={() => setValue('adAccounts', '')} />
          <Select
            name="adType"
            inputLabel="Ad Type"
            options={TIKTOK_ADS_AD_LIST.map(listMapper)}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <Box sx={{ flex: 1 }}><AdAccountSelect /></Box>
          <Box sx={{ flex: 1 }}></Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export const ChooseCampaign = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const facebookAccountId = watch('adAccounts');
  const selectedCampaigns = watch('selectedCampaigns');
  const isCampaignFieldError = errors['selectedCampaigns']?.message;

  const COLUMNS = [
    { field: 'id', headerName: 'Campaign ID', width: 160 },
    { field: 'campaign_name', headerName: 'Campaign Name', width: 750 },
    { field: 'operation_status', headerName: 'Status', width: 100 },
  ];

  const { data: rows, isLoading } = useCampaigns({
    params: { facebookAccountId },
  });

  const handleSelectionMode = useCallback(
    (rows) => {
      setValue('selectedCampaigns', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedCampaigns');
  }, [trigger, selectedCampaigns]);

  return (
    <FormContainer title={'Choose Campaigns'} error={isCampaignFieldError}>
      <Box style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows ?? []}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedCampaigns}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
        />
      </Box>
    </FormContainer>
  );
};

export const ChooseCreative = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();

  const { rows, isLoading } = useRowsCreatives({ params: { ad_type: 'single_ad', network: 'Tik Tok' } });

  const selectedCreatives = watch('selectedCreatives');
  const isCreativesFieldError = errors['selectedCreatives']?.message;

  const COLUMNS = [
    { field: 'creative_id', headerName: 'Creative ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 450 },
    { field: 'app', headerName: 'App', width: 100 },
    { field: 'creative_type', headerName: 'Type', width: 100 },
    { field: 'created', headerName: 'Created', width: 100 },
  ];

  const handleSelectionMode = useCallback(
    (rows, _) => {
      setValue('selectedCreatives', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedCreatives');
  }, [trigger, selectedCreatives]);

  return (
    <FormContainer title={'Choose Creatives'} error={isCreativesFieldError}>
      <Box style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows ?? []}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedCreatives}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
        />
      </Box>
    </FormContainer>
  );
};

export const FlowsSelect = () => {
  const { watch } = useFormContext();
  const app = watch('app');

  const { data } = useFlows({
    params: { app },
    config: {
      select: (data) =>
        data.map((item) => ({
          value: item.name,
          label: item.name,
        })),
    },
  });
  return (
    <Select
      name="flow"
      inputLabel="Flow"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const BidStrategySelect = () => {
  const { setValue } = useFormContext();

  const bidStrategies = BID_STRATEGIES_LIST.map(({ value, name }) => ({
    value,
    label: name,
  }));

  return (
    <Select
      name="bidStrategy"
      inputLabel="Bid Strategy"
      options={bidStrategies}
      handleChange={() => {
        setValue('targetROAS', DEFAULT_TARGET_ROAS);
        setValue('targetCPA', DEFAULT_TARGET_CPA);
      }}
    />
  );
};

export const TextsSelect = () => {
  const { watch } = useFormContext();
  const app = watch('app');
  const adType = watch('adType');

  const { data } = useTexts({
    params: { app },
    config: {
      enabled: adType === 'SINGLE_VIDEO',
      select: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.text,
        })),
    },
  });

  return adType === 'SINGLE_VIDEO' ? (
    <Select
      name="textId"
      inputLabel="Ad Text"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  ) : null;
};

export const CreateAd = () => {
  const bidStrategy = useWatch({ name: 'bidStrategy' });
  const isTargetCPAField = bidStrategy === 'CPA';
  const isTargetROASField = bidStrategy === 'ROAS';

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Settings
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexGrow: 1,
          gap: 12,
          flexWrap: 'nowrap',
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', gap: 3, flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <Typography variant="subtitle2">Team</Typography>
            <RadioGroup
              row
              name="team"
              options={TEAM_ITEMS}
              sx={{ '& .MuiButtonBase-root': { py: 0 } }}
            />
          </Box>
          <TextField name={'addGroupNumber'} inputLabel={'Add Group Number'} />
          <Suspense
            fallback={
              <Skeleton
                height={40}
                sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
              />
            }
          >
            <FlowsSelect />
          </Suspense>
          <OutlinedInput
            name={'budget'}
            label={'Budget'}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <Suspense
            fallback={
              <Skeleton
                height={40}
                sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
              />
            }
          >
            <TextsSelect />
          </Suspense>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Suspense
                fallback={
                  <Skeleton
                    height={40}
                    sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
                  />
                }
              >
                <BidStrategySelect />
              </Suspense>
            </Box>
            {isTargetCPAField && (
              <Box sx={{ flex: 1 }}>
                <TextField
                  name={'targetCPA'}
                  inputLabel={'Target CPA'}
                  type="number"
                />
              </Box>
            )}
            {isTargetROASField && (
              <Box sx={{ flex: 1 }}>
                <TextField
                  name={'targetROAS'}
                  inputLabel={'Target ROAS'}
                  type="number"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle2">Spending power</Typography>
            <RadioGroup
              row
              name="spendingPower"
              options={SPENDING_POWER_ITEMS}
            />
          </Box>
        </Box>
        <Box
          sx={{
            pl: 12,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            borderLeft: `1px solid #ccc`,
          }}
        >
          <ChipGroup
            row
            name="age"
            label={'Age'}
            controlProps={{ sx: { gap: 0.5 } }}
            options={AGE_RANGE_ITEMS}
          />
          <ChipGroup
            row
            name="gender"
            label={'Gender'}
            controlProps={{ sx: { gap: 0.5 } }}
            options={GENDER_ITEMS}
          />
        </Box>
      </Box>
    </>
  );
};

export const JobResultTable = ({ rows }) => {
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 220 }}>Account Ad ID</TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              AdGroup
            </TableCell>
            <TableCell align="center">Creative</TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              Result
            </TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              Ad ID
            </TableCell>
            <TableCell align="center">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={`${i}:${row.name}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.advertiser_id}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {' '}
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.adgroup_name}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.creative_name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {row.is_successful ? '✅' : '❌'}
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.ad_id}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.message}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
