import { CheckCircleOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const AlertMessage = ({ message }: { message: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        paddingRight: 2,
        paddingY: 1,
        maxWidth: 340,
      }}
    >
      <CheckCircleOutline sx={{ marginRight: 1 }} />
      <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize, wordBreak: 'break-word', fontWeight: 500 })}>
        {message}
      </Typography>
    </Box>
  );
};

export default AlertMessage;
